import React, { useState, useContext, useCallback } from "react";
import useFetch from "../hooks/useFetch";
import { useHistory, Link } from "react-router-dom";
import { StoreContext } from "../context";
import Breadcrumbs from "../components/Breadcrumbs";
import Loader from "../components/Loader";

export default function Product({ id }) {
  
  const { response: product, error } = useFetch(null, null, id);
  const history = useHistory();
  const { setCart } = useContext(StoreContext);

  const [additionalCharges, setAdditionalCharges] = useState([]);
  const [quantity, setQuantity] = useState(1);

  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [specialRequest, setSpecialRequest] = useState("")

  const [isMessageShow, setIsMessageShow] = useState(false);
  
  const handleChangeSpecialRequest = (e) => {
    e.preventDefault();
    setSpecialRequest(e.target.value);
  }

  const handleChangeTime = (e) => {
    e.preventDefault();
    setTime(e.target.value);
  }

  const handleChangeDate = (e) => {
    e.preventDefault();
    setDate(e.target.value);
  }

  const handleChange = (e, id, name, label, price, price_checkout, value) => {
  
    var target_index = additionalCharges.map(function(e) { return e.id; }).indexOf(id);

    if (target_index !== -1) {
      additionalCharges.splice(target_index, 1);
    }
    else {
      setAdditionalCharges(additionalCharges => [...additionalCharges, {id: id, name: name, label: label, price: price, price_checkout: price_checkout, value: value}]);
    }
  }

  const handleAddCart = (e) => {
    e.preventDefault();

    if (time != "" && date != "")
    {
      setIsMessageShow(false);

      setCart((cart) => {

        const itemIndex = cart.findIndex((item) => item.id === id);
  
        if (itemIndex !== -1) {
          if (quantity <= cart[itemIndex].max) cart[itemIndex].amount = quantity;
          return [...cart];
        }

        return [
          ...cart,
          {
            id,
            name,
            //amount : quantity,
            quantity,
            image: image,
            //max: stock,
            price,
            price_checkout: parseFloat(price_checkout),
            description,
            specialRequest,
            startingTime: time,
            booking: date,
            additional: additionalCharges
          },
        ];
      });
  
      history.push("/cart");
    }
    else
    {
      setIsMessageShow(true);
    }
  };

  if (!product) return <Loader />;
  if (error) return <div>Error.</div>;

  const {
    stock,
    price,
    // colors,
    image,
    reviews,
    stars,
    name,
    description,
    company,
    price_checkout,
    additional,
  } = product;

  const roundedStarNumber = Math.round(stars);
  const filledStar = (
    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
  );
  const emptyStar = (
    <path
      fillRule="evenodd"
      d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.523-3.356c.329-.314.158-.888-.283-.95l-4.898-.696L8.465.792a.513.513 0 00-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767l-3.686 1.894.694-3.957a.565.565 0 00-.163-.505L1.71 6.745l4.052-.576a.525.525 0 00.393-.288l1.847-3.658 1.846 3.658a.525.525 0 00.393.288l4.052.575-2.906 2.77a.564.564 0 00-.163.506l.694 3.957-3.686-1.894a.503.503 0 00-.461 0z"
      clipRule="evenodd"
    ></path>
  );

  // const Checkbox = props => (
  //   <input type="checkbox" {...props} />
  // )

  return (
    <>
      <Breadcrumbs>
        <Link to="/">Home</Link>
        <Link to="/products">Products</Link>
        <span>{name}</span>
      </Breadcrumbs>

      <section>
        <div className="tw-container py-16">
          <div className="grid lg:grid-cols-2 items-center gap-16 mt-6">
            <div className="grid-col-6">
            <img
              alt={"image"}
              className="main-product-image-sm md:main-product-image-md lg:main-product-image bg-gray-300 rounded object-cover w-full"
              src={image}
              style={{ top: 200 }}
            />
            </div>
            
            <article
              id="product-info"
              className="capitalize flex flex-col gap-5 lg:gap-4 text-sm md:text-base"
            >
              <div>
                <h2 className="font-bold">{name}</h2>
                <Link to="/products" className="btn-sm bg-primary text-white w-max mt-4">
                  Back to Products
                </Link>
              </div>

              <h4 className="font-bold text-lg lg:text-2xl text-primary">{price}</h4>

              <span className="font-bold">Product Description:</span>
              <p>{description}</p>

              <div className="flex flex-col gap-4">
                <p className="grid">
                  {additional.length > 0 ? (
                    <>
                      <span className="font-bold">Additional Services:</span>
                      {additional.map((item, index) => {
                      return (
                        <div>
                          <label>
                            <input type="checkbox" 
                              onChange={(e) => {
                                //handleChange(e, index, item.id, item.name, item.price_checkout);
                                handleChange(e, item.id, item.name, item.label, item.price, item.price_checkout, item.value);
                              }}
                            />
                            <span className="leading-loose mx-2">{item.label}</span>
                          </label>
                        </div>
                        ) 
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </p>

                <label className="font-bold mt-2">Special Request from Client:</label>
                <textarea className="md:w-1/2" value={specialRequest} onChange={handleChangeSpecialRequest} placeholder="Enter your text"></textarea>

                <label className="font-bold mt-4">Starting Time:</label>
                <input type="time" className="md:w-1/2" name="time" value={time} 
                  onChange={handleChangeTime}
                />

                <label className="font-bold mt-4">Booking</label>
                <input type="date" className="md:w-1/2" name="date" value={date} 
                  onChange={handleChangeDate} 
                />

                <button type="button"
                    className="btn bg-primary text-white md:w-1/2 mt-4"
                    onClick={handleAddCart}
                  >
                    Add To Cart
                </button>

                {isMessageShow ? (
                  <div class="flex md:w-1/2 p-2 mb-0 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                      <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg>
                      <div>
                          <span class="font-medium">Please select Time and Date</span>
                      </div>
                  </div>
                ) : (
                    <></>
                )}
              </div>
            </article>
          </div>
        </div>
      </section>
    </>
  );
}