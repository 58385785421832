import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";

export default function Login() {
    
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [isMessageShow, setIsMessageShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleChangeEmail = (e) => {
        e.preventDefault();
        setEmail(e.target.value);
    }

    const handleChangePassword = (e) => {
        e.preventDefault();
        setPassword(e.target.value);  
    }

    const handleLogin = (e) => {
        e.preventDefault();

        if (email !== "" && password !== "") {
            setIsLoading(true);
            setIsMessageShow(false);

            var body = {
                email: email,
                password: password
            }
            
            axios({
                method: 'post',
                url: 'https://api.relaxsa.co.za/public/api/login',
                data: body
            })
            .then(function (response) {
                if (!response.data.success) {
                    setIsLoading(false)   // Hide loading screen
                    setIsMessageShow(true);
                    setAlertMessage(response.data.message);
                }
                else {
                    window.localStorage.setItem("user", JSON.stringify(response.data));
                    window.localStorage.setItem("login", response.data.success);
        
                    setEmail("");
                    setPassword("");
                    setIsLoading(false)   // Hide loading screen
                    history.push("/products");
                }
            })
            .catch(function (error) {
                console.log(error);
                setIsLoading(false)   // Hide loading screen
            });
        }
        else {
            setIsMessageShow(true);
            setAlertMessage("Please input email and password");
        }
    }

    const loginForm = (
        <div className="tw-container flex justify-center items-center" style={{height: "74vh"}}>
            <form className="shadow md:w-2/5 h-1\/4 py-8 px-8 table">
                {isMessageShow ? (
                    <div class="flex w-full p-2 mb-0 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                        <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg>
                        <div>
                            <span class="font-medium">{alertMessage}</span>
                        </div>
                    </div>
                ) : (
                    <></>
                )}

                <div className="font-bold text-center">
                    <h2>Login</h2>
                </div>

                <div className="w-full mt-8">
                    <label>Email</label>
                    <input type="email" className="w-full" name="email" value={email} onChange={handleChangeEmail} />
                </div>

                <div className="w-full mt-4">
                    <label>Password</label>
                    <input type="password" className="w-full" name="password" value={password} onChange={handleChangePassword} />
                </div>

                <div className="w-full mt-2">
                    <Link to="/forgot-password">Forgot your password?</Link>
                </div>

                <button className="btn bg-primary text-white w-full h-10 mt-4" onClick={handleLogin} disabled={isLoading}>Login</button>
                {/* <MessageBox ref="messageBox"/> */}

                <div className="w-full mt-2 text-center">
                    <label>Don't have an account?</label>
                </div>

                <Link to="/signup">
                    <button className="btn bg-primary text-white w-full h-10 mt-4">Signup</button>
                </Link>
            </form>
        </div>
    );

    return (
        <div>
            {isLoading ? <Loader /> : loginForm}
        </div>
    );
}