import React, { useState } from "react";
import axios from "axios";
import Loader from "../components/Loader";

export default function ForgotPassword() {

    const [isLoading, setIsLoading] = useState(false);
    const [messageStatus, setMessageStatus] = useState(0);
    const [alertMessage, setAlertMessage] = useState("");

    const [email, setEmail] = useState("");

    const handleChangeEmail = (e) => {
        e.preventDefault();
        setEmail(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (email !== "") {
            setIsLoading(true);
            
            var body = {
                email: email,
                url: "https://api.relaxsa.co.za/public/api/resetpassword"
            }
            
            axios({
                method: 'post',
                url: 'https://api.relaxsa.co.za/public/api/save-forgot-password',
                data: body
            })
            .then(function (response) {
                if (!response.data.success) {
                    setIsLoading(false)   // Hide loading screen
                    setMessageStatus(2);
                    setAlertMessage(response.data.message);
                }
                else {
                    window.localStorage.setItem("user_email", email);
                    setEmail("");
                    setIsLoading(false)   // Hide loading screen
                    setMessageStatus(1);
                    setAlertMessage(response.data.message);

                    const code = response.data.code;

                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");

                    var raw = JSON.stringify({
                        "email": response.data.email,
                        "name": response.data.name,
                        "url": "https://relaxsa.co.za/reset-password?code=",
                        "code": code
                    });

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    };

                    fetch("https://relaxmail.herokuapp.com/webresetpassword", requestOptions)
                    .then(response => response.text())
                    .then(result => console.log(result))
                    .catch(error => console.log('error', error));
                }
            })
            .catch(function (error) {
                console.log(error);
                setIsLoading(false)   // Hide loading screen
                setMessageStatus(2);
                setAlertMessage(error);
            });
        }
        else {
            setMessageStatus(2);
            setAlertMessage("Please input registered email");
        }
    }

    const forgotPasswordForm = (
        <div className="tw-container flex justify-center items-center" style={{height: "74vh"}}>
            <form className="shadow md:w-2/5 h-1\/4 py-8 px-8 table">
            {messageStatus == 2 ? (
                <div class="flex w-full p-2 mb-0 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                    <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg>
                    <div>
                        <span class="font-medium">{alertMessage}</span>
                    </div>
                </div>
            ) : (
                <></>
            )}

            {messageStatus == 1 ? (
                <div class="flex w-full p-2 mb-0 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
                    <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    <div>
                        <span class="font-medium">{alertMessage}</span>
                    </div>
                </div>
            ) : (
                <></>
            )}

                <div className="font-bold text-center">
                    <h2>Forgot your password?</h2>
                </div>

                <p className="mt-6">Enter your email address to reset your password. You may need to check your spam folder or unblock <br />no-reply@relaxweb.com.</p>

                <div className="w-full mt-8">
                    <input type="email" className="w-full" name="email" value={email} placeholder="Enter your email" onChange={handleChangeEmail} />
                </div>

                <button className="btn bg-primary text-white w-full h-10 mt-4" onClick={handleSubmit}>Submit</button>
            </form>
        </div>
    );

    return (
        <div>
            {isLoading ? <Loader /> : forgotPasswordForm}
        </div>
    );
}