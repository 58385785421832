import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
// import { Alert } from 'react-alert'

export default function ResetPassword() {

    const history = useHistory();

    const search = useLocation().search;
    const code = new URLSearchParams(search).get('code');

    useEffect(() => {
        axios({
            method: 'post',
            url: 'https://api.relaxsa.co.za/public/api/validate-forgot-password',
            data: {code}
        })
        .then(function (response) {
            if (!response.data.success) {
                alert(response.data.message);
                history.push("/login");
            }
            else {
                history.push("/change-password");
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }, []);

    return <div />
}