import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loader from "../components/Loader";
import Avatar from 'react-avatar';
import { FaEdit } from 'react-icons/fa';

export default function Profile() {
    
    const history = useHistory();
    const user = JSON.parse(window.localStorage.getItem("user"));

    if (user == null)
        history.push("/login");

    const [isLoading, setIsLoading] = useState(false);
    const [messageStatus, setMessageStatus] = useState(0);
    const [alertMessage, setAlertMessage] = useState("");
    const [copySuccess, setCopySuccess] = useState('');
    
    const [firstname, setFirstName] = useState(user != null ? user.user[0].firstname : '');
    const [lastname, setLastName] = useState(user != null ? user.user[0].lastname : '');
    const [contact, setContact] = useState(user != null ? user.user[0].contact : '');
    const [email, setEmail] = useState(user != null ? user.user[0].email : '');
    const [address, setAddress] = useState(user != null ? user.user[0].address : '');
    const [suburbs, setSuburbs] = useState(user != null ? user.user[0].suburbs : 0);
    const [postals, setPostals] = useState(user != null ? user.user[0].postals : '');
    const [password, setPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [file, setFile] = useState(user != null && user.user[0].avatar != "" ? user.user[0].avatar : "");
    const [fileInput, setFileInput] = useState(null);
    const [userReferalCode, setUserReferalCode] = useState(user != null ? "user_referal_code_" + user.user[0].id : '');

    const [subrub, setSubrub] = useState([]);

    useEffect(() => { // called after the first render
        var requestOptions = {
              method: 'POST',
              redirect: 'follow'
        };
    
        async function fetchData() {
          await fetch("https://api.relaxsa.co.za/public/api/getareas", requestOptions)
          .then(response => response.json())
          .then((result) => {
              setSubrub(result.data);
          })
          .catch(function (error) {
              console.log("error :", error);
          });
        }
        fetchData();
    }, []); //[isData] null value will execute once only?

    const handleChangeFirstName = (e) => {
        e.preventDefault();
        setFirstName(e.target.value);
    }

    const handleChangeLastName = (e) => {
        e.preventDefault();
        setLastName(e.target.value);
    }

    const handleChangeContact = (e) => {
        e.preventDefault();
        setContact(e.target.value);
    }

    const handleChangeEmail = (e) => {
        e.preventDefault();
        setEmail(e.target.value);
    }

    const handleChangeAddress = (e) => {
        e.preventDefault();
        setAddress(e.target.value);
    }

    const handleChangeSuburbs = (e) => {
        e.preventDefault();
        setSuburbs(e.target.value);
    }

    const handleChangePostals = (e) => {
        e.preventDefault();
        setPostals(e.target.value);
    }

    const handleChangePassword = (e) => {
        e.preventDefault();
        setPassword(e.target.value);  
    }

    const handleChangeConfirmPassword = (e) => {
        e.preventDefault();
        setConfirmPassword(e.target.value);  
    }

    const handleChangeProfile = (event) => {
        if (event.target.files.length > 0) {
            const file = URL.createObjectURL(event.target.files[0]);
            setFile(file);
            setFileInput(event.target.files[0]);
        }
    };

    const handleCopyReferalCode = (e) => {
        e.target.focus();
        navigator.clipboard.writeText(userReferalCode);
        setCopySuccess('Copied!');

        setTimeout(function () {
            setCopySuccess("");
        }, 3000)
    }

    const handleUpdateProfile = (e) => {
        e.preventDefault();
        debugger;
        if (firstname == "" || firstname == undefined) {
            setMessageStatus(2);
            setAlertMessage("First Name is required");
        }
        else if (lastname == "" || lastname == undefined) {
            setMessageStatus(2);
            setAlertMessage("Last Name is required");
        }
        else if (email == "" || email == undefined) {
            setMessageStatus(2);
            setAlertMessage("Email is required");
        }
        else if ((password != "" && password != undefined) && (confirm_password == "" || confirm_password == undefined)) {
            setMessageStatus(2);
            setAlertMessage("Confirm Password is required");
        }
        else if ((confirm_password != "" && confirm_password != undefined) && (password == "" || password == undefined)) {
            setMessageStatus(2);
            setAlertMessage("Password is required");
        }
        else if (password != confirm_password) {
            setMessageStatus(2);
            setAlertMessage("Password should be match");
        }
        else {
            setIsLoading(true);
            debugger;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + user.data.token);

            var formdata = new FormData();
            formdata.append("firstname", firstname);
            formdata.append("lastname", lastname);
            formdata.append("contact", contact);
            formdata.append("email", email);
            formdata.append("address", address == null ? "" : address);
            formdata.append("suburbs", suburbs == null ? "" : suburbs);
            formdata.append("postals", postals == null ? "" : postals);
            
            if (password != "" && confirm_password != "") {
                formdata.append("password", password);
                formdata.append("confirm_password", confirm_password);
            }

            if (fileInput != null && fileInput != undefined)
                formdata.append("avatar", fileInput, file);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch("https://api.relaxsa.co.za/public/api/profile/update", requestOptions)
            .then(response => response.json())
            .then((result) => {
                setIsLoading(false)   // Hide loading screen
                setMessageStatus(1);
                setAlertMessage(result.message);

                setTimeout(function () {
                    setMessageStatus(0);
                    setAlertMessage("");
                    // debugger;
                    // console.log("user.user[0] :", user.user[0]);
                    // console.log("result.data :", result.data[0]);
                    user.user[0] = result.data[0];
                    window.localStorage.setItem("user", JSON.stringify(user));
                    history.push();
                }, 3000)
            })
            .catch(function (error) {
                console.log(error);
                setIsLoading(false)   // Hide loading screen
                setMessageStatus(2);
                setAlertMessage(error);
            });
        }
    }

    const profileForm = (
        <section>
            <div className="tw-container grid grid-cols-3 my-8 divide-x">
                <div className="flex justify-center items-center flex-col">
                    <input type="file" 
                        onChange={handleChangeProfile} 
                        id="upload" accept="image/*" 
                        style={{display: "none"}}
                    />
                    <Avatar 
                        name={user != null && user.user[0].avatar == "" ? user.user[0].name : ""} 
                        src={file} 
                        round="20px" 
                    />
                    <label htmlFor="upload">
                        <FaEdit color="primary" aria-label="upload picture" component="span" />
                    </label>
                    <label htmlFor="avatar"/>

                    <h3 className="font-bold capitalize leading-loose">{user != null ? user.user[0].name : ''}</h3>
                    <h6>{user != null ? user.user[0].email : ''}</h6>

                    <button 
                        className="btn bg-primary text-white h-10 mt-5" 
                        onClick={handleCopyReferalCode}
                    >
                    Copy Referal Code
                    </button>

                    {copySuccess != "" ? (
                        <div class="flex w-1/4 p-2 mt-4 mb-0 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
                            <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            <div>
                                <span class="font-medium">{copySuccess}</span>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                
                <div className="col-span-2 pl-12">
                    <div className="flex mb-4">
                        <h3 className="font-bold w-1/4 ">Profile</h3>

                        {messageStatus == 2 ? (
                            <div class="flex w-3/4 p-2 mb-0 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                                <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg>
                                <div>
                                    <span class="font-medium">{alertMessage}</span>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {messageStatus == 1 ? (
                            <div class="flex w-3/4 p-2 mb-0 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
                                <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                <div>
                                    <span class="font-medium">{alertMessage}</span>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    
                    <form className="h-1\/4 py-4 grid grid-cols-2 gap-4">

                        <div className="w-full mt-2">
                            <label>First Name</label>
                            <input type="text" className="w-full" name="firstname" value={firstname} onChange={handleChangeFirstName} />
                        </div>

                        <div className="w-full mt-2">
                            <label>Last Name</label>
                            <input type="text" className="w-full" name="lastname" value={lastname} onChange={handleChangeLastName} />
                        </div>

                        <div className="w-full">
                            <label>Contact</label>
                            <input type="text" className="w-full" name="contact" value={contact} onChange={handleChangeContact} />
                        </div>

                        <div className="w-full">
                            <label>Email</label>
                            <input type="email" className="w-full" name="email" value={email} onChange={handleChangeEmail} />
                        </div>

                        <div className="w-full">
                            <label>Address</label>
                            <input type="text" className="w-full" name="address" value={address} onChange={handleChangeAddress} />
                        </div>

                        <div className="w-full">
                            <label>Suburb</label>
                            <select class="w-full bg-gray-50" onChange={handleChangeSuburbs} value={suburbs} defaultValue={suburbs}>
                                <option value="Select">Select</option>
                                {subrub.length > 0 && subrub != undefined ? (
                                <>
                                
                                {subrub.map((item, i) => {
                                    const { Suburb } = item;
                                    return (
                                        <option value={Suburb}>{Suburb}</option>
                                        );
                                    })}
                                </>
                                ) : (
                                    <></>
                                )}
                            </select>
                        </div>

                        <div className="w-full">
                            <label>Postal</label>
                            <input type="text" className="w-full" name="postals" value={postals} onChange={handleChangePostals} />
                        </div>

                        <div className="w-full">
                            <label>Password</label>
                            <input type="password" className="w-full" name="password" value={password} onChange={handleChangePassword} />
                        </div>

                        <div className="w-full">
                            <label>Confirm Password</label>
                            <input type="password" className="w-full" name="confirmPassword" value={confirm_password} onChange={handleChangeConfirmPassword} />
                        </div>

                        <button className="btn bg-primary text-white h-10 mt-5" onClick={handleUpdateProfile} disabled={isLoading}>Update Profile</button>
                    </form>
                </div>
            </div>
        </section>
    );

    return (
        <div>
            {isLoading ? <Loader /> : profileForm}
        </div>
    );
}