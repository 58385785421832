import React, { useContext, useState, useEffect } from "react";
import { useLocation, NavLink, Link, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { StoreContext } from "../context/index";
import Avatar from 'react-avatar';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <button className="flex btn bg-primary text-white"
      //onClick={loginWithRedirect}
    >
      <Link to="/login">Login</Link>
    </button>
  );
};

const CartButton = ({ items }) => (
  <Link to="/cart" className="flex items-center gap-2 text-2xl lg:text-xl">
    {/* Cart */}
    <div className="relative w-6">
      <svg
        stroke="#fff"
        fill="#fff"
        strokeWidth="0"
        viewBox="0 0 576 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"></path>
      </svg>
      <div className="absolute w-6 h-6 text-sm text-white bg-white lg:bg-primary lg:text-white rounded-full top-0 right-0 flex justify-center items-center transform translate-x-4 -translate-y-4">
        {items}
      </div>
    </div>
  </Link>
);

function Navbar() {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { isAuthenticated } = useAuth0();
  const { cart } = useContext(StoreContext);
  const [width, setWidth] = useState(window.innerWidth);
  const location = useLocation();
  const history = useHistory();

  const user = JSON.parse(window.localStorage.getItem("user"));
  const is_login = window.localStorage.getItem("login");

  const GiftBox = () => (
    <div className="flex items-center gap-2 text-2xl lg:text-xl">
      <div className="relative w-6">
        <svg 
          stroke="#fff"
          fill="#fff"
          strokeWidth="0"
          xmlns="http://www.w3.org/2000/svg" 
          viewBox="0 0 512 512"
        >
          <path d="M152 0H154.2C186.1 0 215.7 16.91 231.9 44.45L256 85.46L280.1 44.45C296.3 16.91 325.9 0 357.8 0H360C408.6 0 448 39.4 448 88C448 102.4 444.5 115.1 438.4 128H480C497.7 128 512 142.3 512 160V224C512 241.7 497.7 256 480 256H32C14.33 256 0 241.7 0 224V160C0 142.3 14.33 128 32 128H73.6C67.46 115.1 64 102.4 64 88C64 39.4 103.4 0 152 0zM190.5 68.78C182.9 55.91 169.1 48 154.2 48H152C129.9 48 112 65.91 112 88C112 110.1 129.9 128 152 128H225.3L190.5 68.78zM360 48H357.8C342.9 48 329.1 55.91 321.5 68.78L286.7 128H360C382.1 128 400 110.1 400 88C400 65.91 382.1 48 360 48V48zM32 288H224V512H80C53.49 512 32 490.5 32 464V288zM288 512V288H480V464C480 490.5 458.5 512 432 512H288z"/>
        </svg>
        <div className="absolute w-6 h-6 text-sm text-white bg-white lg:bg-primary lg:text-white rounded-full top-0 right-0 flex justify-center items-center transform translate-x-4 -translate-y-4">
          {user != null ? user.user[0].loyalty_points : 0}
        </div>
      </div>
    </div>
  );

  const handleLogout = () => {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("userorder");
    window.localStorage.removeItem("login");
    history.push("/login");
  }

  const LogoutButton = () => {
    const { logout } = useAuth0();
  
    return (
      <>
        <div className="flex justify-between items-center gap-4">
          <Avatar 
            className="flex justify-between items-center"
            src={user.user[0].avatar} 
            size="40"
            round="30px"
          />
          <h6 className="flex justify-between items-center capitalize text-white">
            <Link to='/profile'>
              Hi {user.user[0].name}
            </Link>
          </h6>
          <button
            className="flex btn bg-primary text-white" onClick={handleLogout}
            //onClick={() => logout({ returnTo: window.location.origin })}
          >
            Logout
          </button>
        </div>
      </>
    );
  };

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (width > 768) setMobileMenuOpen(false);
  }, [width]);

  useEffect(() => {
    setMobileMenuOpen(false);
    window.scrollTo(0, 0);
  }, [location]);

  const amount = cart.reduce((total, obj) => {
    total += obj.amount;
    return total;
  }, 0);

  return (
    <>
      <header className="sticky top-0 z-50 bg-dark shadow-md">
        <div className="tw-container h-16 md:h-20 flex justify-between items-center">
          <Link to="/">
            <img src="/logo192.png" className="w-1/3" alt="Relax" />
          </Link>
          <nav className="hidden md:block">
            <ul className="flex gap-8">
              <li>
                <NavLink to="/" className='text-white'>Home</NavLink>
              </li>
              <li>
                <NavLink to="/about" className='text-white'>About</NavLink>
              </li>
              <li>
                <NavLink to="/products" className='text-white'>Products</NavLink>
              </li>
              {/* {isAuthenticated && (
                <li>
                  <NavLink to="/checkout">Checkout</NavLink>
                </li>
              )} */}
            </ul>
          </nav>
          <div>
            <div className="hidden lg:flex gap-8">
              {user != null && user.user[0].loyalty_points > 0 ? <GiftBox /> : <></>}
              <CartButton items={cart.length} />
              {is_login ? <LogoutButton /> : <LoginButton />}
            </div>
            <button className="btn-lg lg:hidden border-0 bg-transparent text-theme-500" onClick={() => setMobileMenuOpen(true)} >
              <i className="fa-lg md:fa-2x fas fa-bars"></i>
            </button>
          </div>
          <div className={`z-20 fixed top-0 left-0 w-full h-screen bg-blue-500 px-5 py-8 transition-transform duration-500 ease-in-out transform ${mobileMenuOpen ? "" : "-translate-x-full"}`}>
            <div className="w-full h-full text-primary">
              <div className="flex justify-between items-center">
                <Link to="/" className="flex items-center gap-2 font-semibold text-white">
                  <i className="fa-2x fas fa-couch"></i>
                  Relax
                </Link>
                <span className="cursor-pointer text-3xl font-semibold" onClick={() => setMobileMenuOpen(false)}>X</span>
              </div>
              <ul className="mt-7 flex flex-col gap-7 text-xl">
                <li>
                  <NavLink to="/" className='text-white'>Home</NavLink>
                </li>
                <li>
                  <NavLink to="/about" className='text-white'>About</NavLink>
                </li>
                <li>
                  <NavLink to="/products" className='text-white'>Products</NavLink>
                </li>
                {/* {isAuthenticated && (
                  <li>
                    <NavLink to="/checkout">Checkout</NavLink>
                  </li>
                )} */}
              </ul>
              <div className="mt-10 flex gap-8 justify-center">
                {user != null && user.user[0].loyalty_points > 0 ? <GiftBox /> : <></>}
                <CartButton items={amount} />
                {is_login ? <LogoutButton /> : <LoginButton />}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Navbar;