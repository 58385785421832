import React, { useContext, useState, useEffect } from "react";
import { StoreContext } from "../context";
import { Link, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Breadcrumbs from "../components/Breadcrumbs";
import Counter from "../components/Counter";
import Loader from "../components/Loader";

export default function Cart() {

  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { cart, setCart } = useContext(StoreContext);
  const isLoggedIn = window.localStorage.getItem("login");
  const user = JSON.parse(window.localStorage.getItem("user"));
  const userStringify = JSON.stringify(window.localStorage.getItem("user"));
  const loyaltyPoints = user != null ? user.user[0].loyalty_points : 0;

  const history = useHistory();
  
  const [isLoading, setIsLoading] = useState(false);
  const [messageStatus, setMessageStatus] = useState(0);
  const [alertMessage, setAlertMessage] = useState("");

  const [name, setName] = useState(user != null ? user.user[0].firstname + ' ' + user.user[0].lastname : '');
  const [contact, setContact] = useState(user != null ? user.user[0].contact : '');
  const [email, setEmail] = useState(user != null ? user.user[0].email : '');
  const [suburbs, setSuburbs] = useState(user != null ? user.user[0].suburbs : 0);
  const [postals, setPostals] = useState(user != null ? user.user[0].postals : '');
  const [address, setAddress] = useState(user != null ? user.user[0].address : '');
  const [discount, setDiscount] = useState(0);
  const [loyalty, setLoyalty] = useState(0);

  const [subrub, setSubrub] = useState([]);

  useEffect(() => { // called after the first render
    var requestOptions = {
          method: 'POST',
          redirect: 'follow'
    };

    async function fetchData() {
      await fetch("https://api.relaxsa.co.za/public/api/getareas", requestOptions)
      .then(response => response.json())
      .then((result) => {
          setSubrub(result.data);
      })
      .catch(function (error) {
          console.log("error :", error);
      });
    }
    fetchData();
  }, []); //[isData] null value will execute once only?

  const handleChangeName = (e) => {
      e.preventDefault();
      setName(e.target.value);
  }

  const handleChangeContact = (e) => {
      e.preventDefault();
      setContact(e.target.value);
  }

  const handleChangeEmail = (e) => {
      e.preventDefault();
      setEmail(e.target.value);
  }

  const handleChangeSuburbs = (e) => {
      e.preventDefault();
      setSuburbs(e.target.text);
  }

  const handleChangePostals = (e) => {
      e.preventDefault();
      setPostals(e.target.value);
  }

  const handleChangeAddress = (e) => {
    e.preventDefault();
    setAddress(e.target.value);
  }

  const handleSelectDiscount = (e) => {
    e.preventDefault();

    var value = e.target.value;

    if (value == 25)
      setLoyalty(100);
    else if (value == 50)
      setLoyalty(150);
    else if (value == 75)
      setLoyalty(200);
    else if (value == 100)
      setLoyalty(250);

    setDiscount(value);
  }

  const CheckOutButton = () => {
    return (
      <button
        className="text-center btn w-4/5 bg-yellow-600 text-blue-50 font-semibold"
        onClick={handleCheckOut}
      >
       Proceed To Checkout
    </button>
    );
  };

  const LoyaltyDropdown = () => {
    if (loyaltyPoints >= 100 && loyaltyPoints < 150) { 
      return (
        <select class="form-select w-4/5" onChange={handleSelectDiscount}>
          <option value="0" selected>Discount</option>
          <option value="25">25% Off</option>
        </select>
      )
    }
    else if (loyaltyPoints >= 150 && loyaltyPoints < 200) {
      return (
        <select class="form-select w-4/5" onChange={handleSelectDiscount}>
          <option value="0" selected>Discount</option>
          <option value="25">25% Off</option>
          <option value="50">50% Off</option>
        </select>
      )
    }
    else if (loyaltyPoints >= 200 && loyaltyPoints < 250) {
      return (
        <select class="form-select w-4/5" onChange={handleSelectDiscount}>
          <option value="0" selected>Discount</option>
          <option value="25">25% Off</option>
          <option value="50">50% Off</option>
          <option value="75">75% Off</option>
        </select>
      )
    }
    else if (loyaltyPoints >= 250) {
      return (
        <select class="form-select w-4/5" onChange={handleSelectDiscount}>
          <option value="0" selected>Discount</option>
          <option value="25">25% Off</option>
          <option value="50">50% Off</option>
          <option value="75">75% Off</option>
          <option value="100">100% Off</option>
        </select>
      )
    }
    else {
        return <></>
    }
  };

  const confirmation = async (id) => {

    const mainTotal = cart.reduce((total, item) => {
      item.additional.map((subItem) => {
        total += subItem.price_checkout * item.quantity;
      });

      total += item.price_checkout * item.quantity;
      return total;
    }, 0);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const discountAmount = ((mainTotal * discount) / 100);
    
    var body = JSON.stringify({
      "name": name,
      "email": email,
      "contact": contact,
      "total": mainTotal,
      "address": address,
      "transactionId": id,
      "suburbs": suburbs,
      "postal": postals,
      "subject": "New Order Received",
      "discountedprice": (mainTotal - discountAmount),
      "idDiscounted": discount > 0 ? true : false,
      "cart": {
        "data": cart
      }
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: body,
      redirect: 'follow'
    };

    fetch("https://relaxmail.herokuapp.com/orderConfirm", requestOptions)
    .then(response => response.text())
    .then((result) => {
      console.log("result :", result);
    })
    .catch(function (error) {
      console.log("error :", error);
    });
  };

  const orderconfirmation = async (id) => {
    setIsLoading(true);
    
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + user.data.token);

    var formdata = new FormData();
    formdata.append("userid", user.user[0].id);
    formdata.append("orderdetail", userStringify);
    formdata.append("add_loyalty_points", 100);
    formdata.append("edit_loyalty_points", loyalty);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://api.relaxsa.co.za/public/api/create-order", requestOptions)
    .then(response => response.json())
    .then((result) => {
      confirmation(id);
      setIsLoading(false);
      setCart([]);

      user.user[0] = result.user[0];
      window.localStorage.setItem("user", JSON.stringify(user));
      history.push("/");
    })
    .catch(function (error) {
      console.log(error);
      setIsLoading(false)
    });
  };

  const handleCheckOut = () => {

    if (name == null || contact == null || email == null || suburbs == null || postals == null || address == null) {
      setMessageStatus(2);
      setAlertMessage("Fill Checkout Fileds");
    }
    else {
      const mainTotal = cart.reduce((total, item) => {
        item.additional.map((subItem) => {
          total += subItem.price_checkout * item.quantity;
        });
  
        total += item.price_checkout * item.quantity;
        return total;
      }, 0);
  
      var yoco = new window.YocoSDK({
        publicKey: 'pk_test_ed3c54a6gOol69qa7f45',
      })
  
      yoco.showPopup({
        amountInCents: mainTotal + "00",
        currency: 'ZAR',
        name: 'Checkout',
        description: 'Awesome description',
        displayMethod: 'MANUAL',
        callback: function (result) {
          if (result.error) {
            console.log("errorMessage: ", result.error.message);
          } 
          else {
            console.log("card successfully tokenised: ", result.id);
            orderconfirmation(result.id);
          }
        }
      })
    }
  }
  
  const handleAmountChange = (value, max, index) => {
    if (value <= 0 || value > max || !cart[index]) return;
    setCart((cart) => {
      cart[index].quantity = value;
      return [...cart];
    });
  };

  const handleDeleteItem = (index) => {
    if (!cart[index]) return;
    setCart((cart) => cart.filter((_, i) => i !== index));
  };

  const handleClearCart = () => {
    if (window.confirm("You're about to clear shopping cart. Is that okay?")) {
      setCart([]);
    }
  };

  const getSubtotal = () => {
    return cart.reduce((total, item) => {
      item.additional.map((subItem) => {
        total += subItem.price_checkout * item.quantity;
      });

      total += item.price_checkout * item.quantity;
      return total;
    }, 0);
  };

  const cartData = (
    <>
      {cart.length > 0 && cart != undefined ? (
        <>
          <Breadcrumbs>
            <Link to="/">Home</Link>
            <span>Cart</span>
          </Breadcrumbs>
          <section className="py-12">
            <div className="tw-container">
              <article className="hidden lg:block py-10">
                <div
                  className="grid text-center"
                  style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr auto" }}
                >
                  <h5>Item</h5>
                  <h5>Price</h5>
                  <h5>Quantity</h5>
                  <h5>Subtotal</h5>
                  <span className="w-8 h-8"></span>
                </div>
                <hr className="mt-6" />
              </article>

              {cart.map((item, i) => {
                const { name, price, price_checkout, quantity, image, specialRequest, startingTime, booking, color, max } = item;
                return (
                  <article
                    key={i}
                    className="grid cart-grid-cols-2 lg:cart-grid-cols-5 place-items-center mb-6 capitalize"
                  >
                    <div className="flex w-full gap-2 md:gap-4 items-center">
                      <img
                        alt={name}
                        className="object-cover w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 rounded-md"
                        src={image}
                      />
                      <div>
                        <h5 className="text-base md:text-lg lg:text-xl font-semibold">
                          {name}
                        </h5>
                        {item.additional.length > 0 ? (
                          <>
                            <h6 className="text-base font-semibold">Additionla Charges</h6>
                            {item.additional.map((subItem, subIndex) => {
                              return (
                                <h6 className="text-base font-medium">{subItem.name}</h6>
                              )
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                        {specialRequest != "" ? (
                          <>
                            <h6 className="text-base font-semibold">Special Request: {specialRequest}</h6>
                          </>
                        ) : (
                          <></>
                        )}
                        
                        <h6 className="text-base font-semibold">Time: {startingTime}</h6>
                        <h6 className="text-base font-semibold">Date: {booking}</h6>
                        <div className="lg:hidden">{price}</div>
                      </div>
                    </div>
                    <div>
                      <h5 className="hidden lg:block">{price}</h5>
                      {item.additional.length > 0 ? (
                        <>
                          {item.additional.map((subItem, subIndex) => {
                            return (
                              <h5 className="hidden lg:block text-base">R{subItem.price_checkout}</h5>
                            )
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <Counter
                      count={quantity}
                      setCount={(value) => handleAmountChange(value, max, i)}
                      maxNum={max}
                    />
                    <div>
                    <h5 className="hidden lg:block">R{quantity * price_checkout}.00</h5>
                      {item.additional.length > 0 ? (
                        <>
                          {item.additional.map((subItem, subIndex) => {
                            return (
                              <h5 className="hidden lg:block text-base">R{quantity * subItem.price_checkout}.00</h5>
                            )
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <button
                      className="block ml-3 md:ml-0 w-7 h-7 p-2 bg-red-600 text-white rounded"
                      onClick={() => handleDeleteItem(i)}
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 448 512"
                      >
                        <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path>
                      </svg>
                    </button>
                  </article>
                );
              })}

              <hr className="mt-10" />

              <section>
                <div className="grid grid-cols-3">

                  <div className="col-span-2">
                    {messageStatus == 2 ? (
                      <div class="flex p-2 mt-4 mb-0 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                          <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg>
                          <div>
                              <span class="font-medium">{alertMessage}</span>
                          </div>
                      </div>
                    ) : (
                        <></>
                    )}

                    {isLoggedIn ? (
                      <form className="h-1\/4 py-4 grid grid-cols-2 gap-4">
                        <div className="w-full">
                            <label className="font-semibold">Name</label>
                            <input type="text" className="w-full capitalize mt-2" name="name" value={name} onChange={handleChangeName} />
                        </div>

                        <div className="w-full">
                            <label className="font-semibold">Contact</label>
                            <input type="text" className="w-full mt-2" name="contact" value={contact} onChange={handleChangeContact} />
                        </div>

                        <div className="w-full">
                            <label className="font-semibold">Email</label>
                            <input type="email" className="w-full mt-2" name="email" value={email} onChange={handleChangeEmail} />
                        </div>

                        <div className="w-full">
                            <label className="font-semibold">Suburb</label>
                            <select class="w-full bg-gray-50" onChange={handleChangeSuburbs} value={suburbs} defaultValue={suburbs}>
                                <option value="0">Select</option>
                                {subrub.length > 0 && subrub != undefined ? (
                                <>
                                
                                {subrub.map((item, i) => {
                                    const { Suburb } = item;
                                    return (
                                        <option value={Suburb}>{Suburb}</option>
                                        );
                                    })}
                                </>
                                ) : (
                                    <></>
                                )}
                            </select>
                        </div>

                        <div className="w-full">
                            <label className="font-semibold">Postal</label>
                            <input type="text" className="w-full mt-2" name="postals" value={postals} onChange={handleChangePostals} />
                        </div>

                        <div className="w-full">
                            <label className="font-semibold">Address</label>
                            <input type="text" className="w-full mt-2" name="address" value={address} onChange={handleChangeAddress} />
                        </div>
                      </form>
                    ) : (
                      <></>
                    )}
                  </div>
                  
                  <div className="col-span-1">
                    <div className="flex justify-end lg:justify-end py-6">
                      <Link
                        to="/products"
                        className="btn-sm w-max h-9 bg-primary mr-2 text-white text-bold"
                      >
                        Continue Shopping
                      </Link>
                      <button
                        onClick={handleClearCart}
                        className="btn-sm font-bold h-9 text-red-900 bg-red-300"
                      >
                        Clear Cart
                      </button>
                    </div>

                    <div className="flex justify-end lg:justify-end">
                      <article className="md:border px-2 py-4 md:px-6 md:py-8 w-4/5">
                        <h4
                          className="grid text-center"
                          style={{ gridTemplateColumns: "150px 1fr" }}
                        >
                          Order Total:
                          <span>R{getSubtotal()}.00</span>
                        </h4>
                      </article>
                    </div>

                    <div className="flex justify-end lg:justify-end py-3">
                      {isLoggedIn ? (
                        <LoyaltyDropdown />
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="flex justify-end lg:justify-end py-3">
                      {isLoggedIn ? (
                        <CheckOutButton />
                      ) : (
                        <button
                          className="text-center w-4/5 btn bg-yellow-600 text-blue-50 font-semibold"
                        >
                          <Link to="/login">Login</Link>
                        </button>
                      )}
                    </div>
                  </div>

                </div>
              </section>
            </div>
          </section>
        </>
      ) : (
        <div className="tw-container text-center py-20">
          <h2 className="font-bold">Your cart is empty</h2>
          <Link to="/products" className="mt-5 btn bg-primary text-white w-max mx-auto">
            Go to products
          </Link>
        </div>
      )}
    </>
  );

  return (
    <div>
        {isLoading ? <Loader /> : cartData}
    </div>
  );
}
