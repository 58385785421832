import { useState, useEffect } from "react";

const useFetch = (url, options, id) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const Products = [
    {
      id: "1",
      name: "One Bedroom Home",
      price: "R150.00",
      price_checkout: "150.00",
      image: "https://d2j6dbq0eux0bg.cloudfront.net/images/51571013/2105884460.jpg",
      icon: "home",
      category: "office",
      description: "All common areas are included Kitchen, Lounge, Bathroom and toilets, Dining area, Patio",
      shipping: true,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      additional: [
        {
          id: "1",
          name: "Interior windows",
          label: "Interior windows (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "1",
        },
        {
          id: "2",
          name: "Ironing",
          label: "Ironing (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "2",
        },
        {
          id: "3",
          name: "Cleaning of Cupboards",
          label: "Cleaning of Cupboards  (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "3",
        },
        {
          id: "4",
          name: "Wiping of interior walls",
          label: "Wiping of interior walls (R50.00)",
          price: "R50.00",
          price_checkout: "50.00",
          value: "4",
        },
        {
          id: "5",
          name: "Childminder",
          label: "Childminder (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "5",
        },
      ],
    },
    {
      id: "2",
      name: "Two Bedroom Home",
      price: "R210.00",
      price_checkout: "210.00",
      icon: "home",
      image: "https://d2j6dbq0eux0bg.cloudfront.net/images/51571013/2105872386.jpg",
      description: "All common areas are included Kitchen, Lounge, Bathroom and toilets, Dining area, Patio",
      shipping: true,
      additional: [
        {
          id: "1",
          name: "Interior windows",
          label: "Interior windows (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "1",
        },
        {
          id: "2",
          name: "Ironing",
          label: "Ironing (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "2",
        },
        {
          id: "3",
          name: "Cleaning of Cupboards",
          label: "Cleaning of Cupboards  (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "3",
        },
        {
          id: "4",
          name: "Wiping of interior walls",
          label: "Wiping of interior walls (R50.00)",
          price: "R50.00",
          price_checkout: "50.00",
          value: "4",
        },
        {
          id: "5",
          name: "Childminder",
          label: "Childminder (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "5",
        },
      ],
    },
    {
      id: "3",
      name: "Three Bedroom Home",
      price: "R260.00",
      price_checkout: "260.00",
      icon: "home",
      image: "https://d2j6dbq0eux0bg.cloudfront.net/images/51571013/2105870469.jpg",
      description: "All common areas are included Kitchen, Lounge, Bathroom and toilets, Dining area, Patio",
      shipping: true,
      additional: [
        {
          id: "1",
          name: "Interior windows",
          label: "Interior windows (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "1",
        },
        {
          id: "2",
          name: "Ironing",
          label: "Ironing (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "2",
        },
        {
          id: "3",
          name: "Cleaning of Cupboards",
          label: "Cleaning of Cupboards  (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "3",
        },
        {
          id: "4",
          name: "Wiping of interior walls",
          label: "Wiping of interior walls (R50.00)",
          price: "R50.00",
          price_checkout: "50.00",
          value: "4",
        },
        {
          id: "5",
          name: "Childminder",
          label: "Childminder (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "5",
        },
      ],
    },
    {
      id: "4",
      name: "Four Bedroom Home",
      price: "R320.00",
      price_checkout: "320.00",
      icon: "home",
      image: "https://d2j6dbq0eux0bg.cloudfront.net/images/51571013/2105855465.jpg",
      description: "All common areas are included Kitchen, Lounge, Bathroom and toilets, Dining area, Patio",
      shipping: true,
      additional: [
        {
          id: "1",
          name: "Interior windows",
          label: "Interior windows (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "1",
        },
        {
          id: "2",
          name: "Ironing",
          label: "Ironing (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "2",
        },
        {
          id: "3",
          name: "Cleaning of Cupboards",
          label: "Cleaning of Cupboards  (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "3",
        },
        {
          id: "4",
          name: "Wiping of interior walls",
          label: "Wiping of interior walls (R50.00)",
          price: "R50.00",
          price_checkout: "50.00",
          value: "4",
        },
        {
          id: "5",
          name: "Childminder",
          label: "Childminder (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "5",
        },
      ],
    },
    {
      id: "5",
      name: "Five Bedroom Home",
      price: "R380.00",
      price_checkout: "380.00",
      icon: "home",
      image: "https://d2j6dbq0eux0bg.cloudfront.net/images/51571013/2105866416.jpg",
      description: "All common areas are included Kitchen, Lounge, Bathroom and toilets, Dining area, Patio",
      additional: [
        {
          id: "1",
          name: "Interior windows",
          label: "Interior windows (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "1",
        },
        {
          id: "2",
          name: "Ironing",
          label: "Ironing (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "2",
        },
        {
          id: "3",
          name: "Cleaning of Cupboards",
          label: "Cleaning of Cupboards  (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "3",
        },
        {
          id: "4",
          name: "Wiping of interior walls",
          label: "Wiping of interior walls (R50.00)",
          price: "R50.00",
          price_checkout: "50.00",
          value: "4",
        },
        {
          id: "5",
          name: "Childminder",
          label: "Childminder (R0.00)",
          price: "R0.00",
          price_checkout: "0.00",
          value: "5",
        },
      ],
    },
    {
      id: "11",
      name: "Car Wash Service",
      price: "R150.00",
      price_checkout: "150.00",
      icon: "car",
      image: "https://d2j6dbq0eux0bg.cloudfront.net/images/51571013/2105640492.jpg",
      description: "A car , bakkie and SUV wash includes a standard wash and dry with and Vacuum and a tyre polish.Done in the convenience of your home.",
      additional: [
        {
          id: "1",
          name: "Hand wax",
          label: "Hand wax (R50.00)",
          price: "R50.00",
          price_checkout: "50.00",
          value: "1",
        },
        {
          id: "2",
          name: "Engine wash",
          label: "Engine wash (R50.00)",
          price: "R50.00",
          price_checkout: "50.00",
          value: "2",
        },
        {
          id: "3",
          name: "Mini Detail",
          label: "Mini Detail (R250.00)",
          price: "R250.00",
          price_checkout: "250.00",
          value: "3",
        },
      ],
    },
    // {
    //   id: "12",
    //   name: "Pool Cleaning Service",
    //   price: "R200.00",
    //   price_checkout: "200.00",
    //   icon: "swimming-pool",
    //   image: "https://d2j6dbq0eux0bg.cloudfront.net/images/51571013/2105613458.jpg",
    //   description: "Cleaning of leaf traps and filters scrubbing of walls ,basic water analysis and water treatment. NOTE : Pool must be working condition",
    //   additional: []
    // },
    // {
    //   id: "13",
    //   name: "Garden Service",
    //   price: "R200.00",
    //   price_checkout: "200.00",
    //   icon: "tree",
    //   image: "https://d2j6dbq0eux0bg.cloudfront.net/images/51571013/2105636445.jpg",
    //   description: "Includes mowing of the lawn ,pruning of trees and plants,edging of the lawn ,sweeping of driveways and pathways.",
    //   additional: [
    //     {
    //       id: "1",
    //       name: "Fertilzation (fertilizer must be provided )",
    //       label: "Fertilzation (fertilizer must be provided ) (R100.00)",
    //       price: "R100.00",
    //       price_checkout: "100.00",
    //       value: "1",
    //     },
    //     {
    //       id: "2",
    //       name: "Creation of a vegatable garden (items must be provided)",
    //       label:
    //         "Creation of a vegatable garden (items must be provided) (R120.00)",
    //       price: "R120.00",
    //       price_checkout: "120.00",
    //       value: "2",
    //     },
    //     {
    //       id: "3",
    //       name: "Tree Felling (includes disposal of tree )",
    //       label: "Tree Felling (includes disposal of tree ) (R200.00)",
    //       price: "R200.00",
    //       price_checkout: "200.00",
    //       value: "3",
    //     },
    //   ],
    // },
    {
      id: "14",
      name: "Moving in or Moving out Service",
      price: "R250.00",
      price_checkout: "250.00",
      icon: "truck-loading",
      image: "https://d2j6dbq0eux0bg.cloudfront.net/images/51571013/2632477782.jpg",
      description: "Our efficient move-out checklist covers every detail so you can focus on the future with peace of mind. Moving out of a rental? A clean home will help you get your deposit back. Selling your home? We’ll get it in tip top shape for showing. Moving into a new home? We’ll get it clean and sanitized before your move in .",
      additional: []
    }
  ];

  useEffect(() => {

    if (id) {
      let obj = Products.find((o) => o.id === id);
      setResponse(obj);
    }

    if (!url) return;

    const fetchData = async () => {
      try {
        const res = await fetch(url, options);
        const json = await res.json();
        console.log(json);
        setResponse(json);
      } catch (error) {
        //alert(error);
        setError(error);
      }
    };

    fetchData();
  }, [url, options, id]);

  return { response, error };
};

export default useFetch;
