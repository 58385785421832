import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ProductPanel = ({ id, name, image, price, grid = true }) => {
  if (grid) {
    return (
        <>
          <div className="relative rounded h-48">
            <Link to={`/products/${id}`} className="w-10 h-10">
              <img src={image} className="h-full rounded bg-black object-cover w-full" alt={name} />
              <div className="absolute top-0 left-0 rounded w-full h-full transition-opacity duration-500 ease-in-out opacity-0 hover:opacity-100 flex justify-center items-center bg-gray-800 bg-opacity-50">
                <div className="relative w-10 h-10 text-white rounded-full bg-primary p-2.5">
                  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"></path>
                  </svg>
                </div>
              </div>
            </Link>
          </div>
        </>
    );
  }

  return (
    <article className="flex flex-col lg:flex-row gap-4 lg:gap-8 mb-10">
      <div className="relative h-48 w-10/12 md:w-7/12 lg:w-4/12 rounded">
        <img alt={name} className="h-full rounded bg-black object-cover w-full" src={image} />
      </div>
      <footer className="w-full lg:w-8/12 flex flex-col gap-3 justify-center capitalize">
        <div>
          <h4 className="font-bold">{name}</h4>
          <p className="text-blue-500 tracking-widest font-semibold">{price}</p>
        </div>
        <p>
          Cloud bread VHS hell of banjo bicycle rights jianbing umami mumblecore
          etsy 8-bit pok pok +1 wolf. Vexillologist yr dreamcatcher waistcoat,
          authentic ...
        </p>
        <Link to={`/products/${id}`} className="btn bg-blue-500 text-white w-max text-xs">
          Details
        </Link>
      </footer>
    </article>
  );
};

ProductPanel.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  price: PropTypes.number.isRequired,
  grid: PropTypes.bool,
};

export default ProductPanel;
