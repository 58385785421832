import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import ProductPanel from "../components/ProductPanel";
import { StoreContext } from "../context";

const CATEGORIES = [
  "bedroom",
  "office",
  "kitchen",
  "living room",
  "kids",
  "dining",
];

const COMPANIES = ["ikea", "marcos", "liddy", "caressa"];
const COLORS = ["#0000ff", "#000", "#ff0000", "#ffb900", "#00ff00"];

export default function Products() {

  const {
    query,
    setQuery,
    categoryIndex,
    setCategoryIndex,
    freeShipping,
    setFreeShipping,
    price,
    setPrice,
    colorIndex,
    setColorIndex,
    companyIndex,
    setCompanyIndex,
    sortByIndex,
    setSortByIndex,
    viewGrid,
    setViewGrid,
    products,
    clearFilters,
    setCart,
    stock
  } = useContext(StoreContext);

  const filteredProducts =
    products &&
    products.filter((product) => {
      if (
        (query.trim() !== "" &&
          !product.name.includes(query.trim().toLowerCase())) ||
        (categoryIndex > -1 &&
          categoryIndex < 6 &&
          product.category !== CATEGORIES[categoryIndex]) ||
        (companyIndex > -1 &&
          companyIndex < 4 &&
          product.company !== COMPANIES[companyIndex]) ||
        (colorIndex > -1 &&
          colorIndex < 5 &&
          product.colors.find((c) => c !== COLORS[colorIndex])) ||
        product.price > price ||
        (freeShipping &&
          (!product.hasOwnProperty("shipping") || !product.shipping))
      )
        return false;
      return true;
    });

  return (
    <>
      <Breadcrumbs>
        <Link to="/">Home</Link>
        <span>Products</span>
      </Breadcrumbs>
      <section>
        <div className="tw-container py-12 flex flex-col gap-12">
          <article id="products-list">
            <div
              className={`mt-6 ${
                viewGrid ? "grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8" : ""
              }`}
            >
              {products.map((product) => (
                <article>
                  <ProductPanel key={product.id} {...product} grid={viewGrid} />
                  <footer className="flex mt-2 justify-between capitalize">
                    <div className="justify-left">
                      <h6>{product.name}</h6>
                      <p className="text-primary tracking-widest">R{product.price_checkout}</p>
                    </div>
                  </footer>
                </article>
              ))}
            </div>
          </article>
        </div>
      </section>
    </>
  );
}
