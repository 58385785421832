import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Link, useHistory } from 'react-router-dom'
import Breadcrumbs from '../components/Breadcrumbs'

export default function Checkout() {
    const { isAuthenticated } = useAuth0();
    const history = useHistory()

    const is_login = window.localStorage.getItem("login");

    useEffect(() => {
        if(!is_login) history.push('/')
    }, [is_login, history]);

    if(is_login) {
        return (
            <Breadcrumbs>
                <Link to="/">Home</Link>
                Checkout
            </Breadcrumbs>
        )
    }

    return null;
}